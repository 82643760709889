@keyframes blink-animation {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.blink {
    animation-name: blink-animation;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
